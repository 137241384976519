
/* process card item */
.cptThumPrc {width:100%; min-width:212px;}
.cptThumPrc .thumLink {width:100%; height:100%; display:block; cursor: pointer;}
.cptThumPrc .thumImgGroup {width:100%; /*background:#f2f2f2;*/ position:relative;}
.cptThumPrc .thumImgGroup .imgBox {overflow:hidden; width:100%; /*height:255px;*/ font-size:0; border-radius:8px;}
.cptThumPrc .thumImgGroup .imgBox > div {/*height: 274px;*/ overflow:hidden; padding-top:56.27%}
.cptThumPrc .thumImgGroup .imgBox img {position:absolute; left:50%; top:50%; width:100%; object-fit:cover; object-position:center; transform:translate(-50%, -50%);}
.cptThumPrc .thumImgGroup .imgBox .infoBox {width:100%;}
.cptThumPrc .thumImgGroup .imgBox .infoBox:after {content:""; display:block; clear:both;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike {position:absolute; right:12px; top:12px;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike .icon {width:36px; height:36px; font-size:0; background:url(/images/icon/ico-36-fav.svg) center no-repeat;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike.on .icon {width:36px; height:36px; font-size:0; background:url(/images/icon/ico-36-fav-added.svg) center no-repeat;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike .cmt {display:none; padding: 5px 20px 5px 16px; font-size: 14px; line-height: 1.43; letter-spacing: -0.29px; color: #000000; white-space:nowrap; position:absolute; right:40px; top:3px; box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15); background-color: #ffffff;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike .cmt:after {content:""; display:block; width:0; height:0; margin-left:-3px; border-width:3px 5px; border-color:transparent transparent transparent #fff; border-style:solid; position:absolute; top:calc(50% - 2px); right:-10px;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike:hover .cmt {display:block;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike .offView {display:inline;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike .onView {display:none;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike.on .offView {display:none;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike.on .onView {display:inline;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay {position:absolute; right:12px; top:54px;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay .icon {width:36px; height:36px; font-size:0; background:url(/images/icon/ico-36-playlist.svg) center no-repeat;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay.on .icon {width:36px; height:36px; font-size:0; background:url(/images/icon/ico-36-playlist-added.svg) center no-repeat;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay .cmt {display:none; padding: 5px 20px 5px 16px; font-size: 14px; line-height: 1.43; letter-spacing: -0.29px; color: #000000; white-space:nowrap; position:absolute; right:40px; top:3px; box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15); background-color: #ffffff;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay .cmt:after {content:""; display:block; width:0; height:0; margin-left:-3px; border-width:3px 5px; border-color:transparent transparent transparent #fff; border-style:solid; position:absolute; top:calc(50% - 2px); right:-10px;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay:hover .cmt {display:block;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay .offView {display:inline;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay .onView {display:none;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay.on .offView {display:none;}
.cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay.on .onView {display:inline;}
.cptThumPrc .thumImgGroup .imgInfo {padding-top:16px; width:100%; /*height:60px;*/ position:relative; display: flex; align-items: flex-end; justify-content: space-between; line-height:1.5; font-size:0;}
.cptThumPrc .thumImgGroup .imgInfo .badgeBlue,
.cptThumPrc .thumImgGroup .imgInfo .badgeBlack {display:inline-block; padding:0 8px ; height:18px; font-size:10px; color:#fff; font-weight:500; line-height:1.5; border-radius:2px; box-sizing:border-box;}
.cptThumPrc .thumImgGroup .imgInfo .badgeBlue {background-color:#F0F4FF; color:#2D67FF}
.cptThumPrc .thumImgGroup .imgInfo .badgeBlack {background-color:#000;}
.cptThumPrc .thumImgGroup .imgInfo .infoBox {/*height: 32px;*/ font-size: 12px; color:#555; display: flex; align-items: center; line-height:1.5;}
.cptThumPrc .thumImgGroup .imgInfo .infoScale {height: 20px; font-family: Rubik; font-size: 12px; font-weight: normal; line-height: 1.67; letter-spacing: -0.25px; color: #000000; display: block;}
.cptThumPrc .thumImgGroup .imgInfo .infoType {height: 24px; margin-top:-6px; font-family: Rubik; font-size: 14px; font-weight: bold; line-height: 1.71; letter-spacing: -0.29px; color: #000000; display: block;}


.cptThumPrc .thumContGroup {width:100%;}
.cptThumPrc .thumTitle {margin-top:8px;}
.cptThumPrc .thumTitle button {width:100%;}
.cptThumPrc .thumTitle strong {width: 100%; height: 3em; font-size: 16px; font-weight:500; line-height: 1.5; letter-spacing: -0.37px; color: #000000; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; text-align: left;}
.cptThumPrc .thumInfo {height: 24px; margin-top:8px;}
.cptThumPrc .thumInfo .name {font-size: 14px; font-weight: normal; line-height: 1.71; letter-spacing: -0.29px; color: #7c7c7c;}
.cptThumPrc .thumInfo .name:after {content:" âˆ™ ";}
.cptThumPrc .thumInfo .name:last-child:after {content:"";}
.cptThumPrc .thumTag {margin-top:16px;}
.cptThumPrc .thumTag .tag {height:24px; margin-right:4px; padding:4px 10px; font-size: 14px; font-weight: 500; line-height: 1.33; letter-spacing: -0.5px; color: #3c89f9; background:#ebf3ff; display: inline-block;}
.cptThumPrc .thumEtc {display:flex; align-items:center; flex-direction:column; margin-top:4px; font-size:0;}

.cptThumPrc .thumEtc .free {margin-top:16px; width:100%; /*height: 20px;*/ font-size: 12px; line-height: 1.5; letter-spacing: -0.25px; color: #555; display:inline-block; position:relative; }
.cptThumPrc .thumEtc .unit {display:flex; align-items:center; justify-content:start; width:100%;}
.cptThumPrc .thumEtc .like {height: 20px; padding-left:20px; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px; color: #555; display:inline-block; position:relative; }
.cptThumPrc .thumEtc .like:after {content:""; width:16px; height:16px; background:url(/images/icon/ico-24-favorite.svg) center no-repeat; display:block; position:absolute; left:0; top:2px; background-size:100% auto; filter: invert(33%) sepia(0%) saturate(0%) hue-rotate(235deg) brightness(98%) contrast(93%);}
.cptThumPrc .thumEtc .liked {height: 20px; padding-left:20px; /*margin-left:16px;*/ font-size: 12px; line-height: 1.67; letter-spacing: -0.25px; color: #555; display:inline-block; position:relative; }
.cptThumPrc .thumEtc .liked:after {content:""; width:16px; height:16px; background:url(/images/icon/ico-16-like-blue.svg) center no-repeat; display:block; position:absolute; left:0; top:2px;}
.cptThumPrc .thumEtc .view {height: 20px; padding-left:20px; margin-left:16px; font-size: 12px; line-height: 1.67; letter-spacing: -0.25px; color: #555; display:inline-block; position:relative;}
.cptThumPrc .thumEtc .view:after {content:""; width:16px; height:16px; background:url(/images/icon/ico_view.svg) center no-repeat; display:block; position:absolute; left:0; top:2px; background-size:100% auto;}
.cptThumPrc .thumGroup {height: 24px; margin-top:8px;}
.cptThumPrc .thumGroup .name {font-size: 14px; font-weight: normal; line-height: 1.71; letter-spacing: -0.29px; color: #7c7c7c;}
.cptThumPrc .thumGroup .name:after {content:" ∙ ";}
.cptThumPrc .thumGroup .name:last-child:after {content:"";}

/*
@media screen and (min-width: 100px) and (max-width: 1279px) {
    .cptThumPrc .thumImgGroup .imgBox { overflow:hidden;}
    .cptThumPrc .thumImgGroup .imgBox img {}
}
*/




@media only screen and (min-width: 100px) and (max-width: 1279px) {

    .cptThumPrc {min-width:unset; width:100%;}
    .cptThumPrc .thumImgGroup .imgBox {/*height:172px !important;*/}
    .cptThumPrc .thumImgGroup .imgBox img {/*height:172px;*/}
    .cptThumPrc .thumImgGroup .imgBox .infoBox {padding-right:15px; padding-top:8px;}
    .cptThumPrc .thumImgGroup .imgBox .infoBox li {margin-left:12px; position:relative; float:left;}
    .cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike {position:relative; right:auto; top:auto;}
    .cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike .icon {width:24px; height:24px; font-size:0; background:url(/images/icon/ico-24-fav.svg) center no-repeat;}
    .cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike.on .icon {width:24px; height:24px; font-size:0; background:url(/images/icon/ico-24-fav-added.svg) center no-repeat;}
    .cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike .cmt {padding:12px 28px; font-weight: 500; color: #ffffff; transform: translateX(-50%); left: 50%; right:auto; top:-52px; background-color: #000000;}
    .cptThumPrc .thumImgGroup .imgBox .infoBox .infoLike .cmt:after {margin-left:-4px; border-width:8px 5px; border-color:#000000 transparent transparent transparent; left:50%; right:auto; top:auto; bottom:-16px;}
    .cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay {position:relative; right:auto; top:auto;}
    .cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay .icon {width:24px; height:24px; font-size:0; background:url(/images/icon/ico-24-playlist.svg) center no-repeat;}
    .cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay.on .icon {width:24px; height:24px; font-size:0; background:url(/images/icon/ico-24-playlist-added.svg) center no-repeat;}
    .cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay .cmt {padding:12px 28px; font-weight: 500;  color: #ffffff; transform: translateX(-50%); left: 50%; right:auto; top:-52px; background-color: #000000;}
    .cptThumPrc .thumImgGroup .imgBox .infoBox .infoPlay .cmt:after {margin-left:-4px; border-width:8px 5px; border-color:#000000 transparent transparent transparent; left:50%; right:auto; top:auto; bottom:-16px;}
    .cptThumPrc .thumImgGroup .imgInfo {/*height:40px;*/}
    .cptThumPrc .thumImgGroup .imgInfo .badgeBlue {height:24px; padding:2px 10px 0 10px; font-size: 12px;}
    .cptThumPrc .thumImgGroup .imgInfo .badgeBlack {height:24px; padding:2px 10px 0 10px; font-size: 12px;}
    .cptThumPrc .thumImgGroup .imgInfo .infoBox {display:none;}

    .cptThumPrc .thumTitle {/*height: 24px;*/ margin-top:8px;}
    .cptThumPrc .thumTitle h4 {height: 24px; font-size: 14px;}
    .cptThumPrc .thumInfo {height: 20px; margin-top:2px;}
    .cptThumPrc .thumInfo .name {font-size: 12px;}
    .cptThumPrc .thumTag {height: 20px; margin-top:2px;}
    .cptThumPrc .thumTag .tag {height:auto; margin:0; padding:0; font-size: 12px; font-weight: normal; line-height: 1.71; letter-spacing: -0.29px; color: #7c7c7c; background:#fff;}
    .cptThumPrc .thumTag .tag:after {content:" ∙ ";}
    .cptThumPrc .thumTag .tag:last-child:after {content:"";}
    .cptThumPrc .thumEtc {margin-top:4px; font-size:0;}
    .cptThumPrc .thumEtc .view {margin-left:8px;}

    .cptThumPrc.searchType { display: flex; flex-direction: row; gap: 18px; }
    .cptThumPrc.searchType .thumImgGroup { width: 120px; background-color: unset; }
    .cptThumPrc.searchType .thumImgGroup .imgInfo { position: unset; height: unset; display: flex; flex-direction: row; }
    .cptThumPrc.searchType .thumImgGroup .imgInfo > div:first-child { position: absolute; height: 35px; top:0; left: 0; }
    .cptThumPrc.searchType .thumImgGroup .imgInfo > div:first-child .badgeBlack { height: 35px; line-height: 35px; }
    .cptThumPrc.searchType .thumImgGroup .imgBox { width: 120px; height:100px !important; }
    .cptThumPrc.searchType .thumImgGroup .imgBox > div {height: 100px !important}
    .cptThumPrc.searchType .thumImgGroup .imgBox img { width: 120px; height: 100px; }
    .cptThumPrc.searchType .thumImgGroup .imgBox { width: 120px; height:100px !important; }
    .cptThumPrc.searchType .thumImgGroup .imgBox > div {height: 100px !important}
    .cptThumPrc.searchType .thumImgGroup .imgBox img { width: 120px; height: 100px; }

    .cptThumPrc.searchType .thumContGroup { height: fit-content }
    .cptThumPrc .thumEtc .view { display: none; }

}

.hide {
    font-size: 0 !important;
    position: fixed;
    left: -5000px;
    top: 0;
}
